.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #CA7E52;
    /* backgroundColor: rgba(0,0,0,0.6); */
    position: fixed ;
    left:45%;
    bottom: 40%;;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
    
   }
   .loading-screen {
    background-color: rgba(0,0,0,0.5);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  z-index: 99999;
  }
   @-webkit-keyframes spin {
     0% { -webkit-transform: rotate(0deg); }
     100% { -webkit-transform: rotate(360deg); }
   }
   
   @keyframes spin {
     0% { transform: rotate(0deg); }
     100% { transform: rotate(360deg); }
   }