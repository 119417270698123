*{
    margin: 0;
    padding: 0;
    /* /outline: none;P
    /font-family: "Poppins", sans-serif; */
    box-sizing: border-box;
  }

  ::selection {
    color: #fff;
    background: var(--primary);
  }
  .container {
    width: var(--containerWidth);
    background: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 10px 20px;
    margin: auto;
  }
  .container header {
    font-size: 35px;
    font-weight: 600;
    margin: 0 0 30px 0;
  }

  .slidePage {
    margin-left: 20px !important;
  }

  .container .form-outer {
    width: 50%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .container .form-outer form {
    display: flex;
    width: calc(100% * var(--stepNumber));
  }
  .form-outer form .page {
    width: calc(100% / var(--stepNumber));
    transition: margin-left 0.3s ease-in-out;
  }

  .step-gap {
    margin-left: 100px;
  }

  .image-add {
    background-image: url("https://liv180.uxdlabtech.com/wp-content/uploads/2023/09/Frame-7-1-1.png");
    z-index: 999 !important;
    background-position: center right !important; /* Adjusted background position for better responsiveness */
    background-size: cover;
    background-repeat: no-repeat;
  }

  .image-add2 {
    background-image: url("https://liv180.uxdlabtech.com/wp-content/uploads/2023/09/Frame-9-2.png");
    z-index: 999 !important;
    background-position: right !important;
    background-size: cover;
  }

  .image-add3 {
    background-image: url("https://liv180.uxdlabtech.com/wp-content/uploads/2023/09/Frame-10-2.png");
    z-index: 999 !important;
    background-position: right !important;
    background-size: cover;
  }

  .page {
    height: fit-content !important;

    padding-bottom: 25px;
  }

  .add-class {
    width: 100% !important;
    text-align: start !important;
  }
  @keyframes animate {
    100% {
      transform: scaleX(1);
    }
  }

  @media (max-width: 575.98px) {
    .image-add {
      background-image: url("https://liv180.uxdlabtech.com/wp-content/uploads/2023/09/Frame-7-1-1.png");
      z-index: 999 !important;
      background-position: center right !important;
      background-size: unset;
      background-repeat: no-repeat;
    }
    .image-add2 {
      background-image: url("https://liv180.uxdlabtech.com/wp-content/uploads/2023/09/Frame-7-1-1.png");
      z-index: 999 !important;
      background-position: center right !important;
      background-size: unset;
      background-repeat: no-repeat;
    }
    .image-add3 {
      background-image: url("https://liv180.uxdlabtech.com/wp-content/uploads/2023/09/Frame-7-1-1.png");
      z-index: 999 !important;
      background-position: center right !important;
      background-size: unset;
      background-repeat: no-repeat;
    }
  }

  /* Small Devices (Tablets and Large Phones) */
  @media (min-width: 576px) and (max-width: 767.98px) {
    .image-add {
      background-image: url("https://liv180.uxdlabtech.com/wp-content/uploads/2023/09/Frame-7-1-1.png");
      z-index: 999 !important;
      background-position: center right !important;
      background-size: unset;
      background-repeat: no-repeat;
    }
    .image-add2 {
      background-image: url("https://liv180.uxdlabtech.com/wp-content/uploads/2023/09/Frame-7-1-1.png");
      z-index: 999 !important;
      background-position: center right !important;
      background-size: unset;
      background-repeat: no-repeat;
    }
    .image-add3 {
      background-image: url("https://liv180.uxdlabtech.com/wp-content/uploads/2023/09/Frame-7-1-1.png");
      z-index: 999 !important;
      background-position: center right !important;
      background-size: unset;
      background-repeat: no-repeat;
    }
  }

  /* Medium Devices (Tablets and Small Laptops) */
  @media (min-width: 768px) and (max-width: 991.98px) {
    /* Your CSS rules for medium devices go here */
  }

  /* Large Devices (Laptops and Desktops) */
  @media (min-width: 992px) and (max-width: 1199.98px) {
  }

  /* Extra Large Devices (Large Desktops) */
  @media (min-width: 1200px) {
  }

  @media screen and (max-width: 490px) {
    :root {
      --containerWidth: 100%;
    }
    .container {
      box-sizing: border-box;
      border-radius: 0;
    }
  }
  